import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Running focused development and data visualization.`}</p>
    <h2 style={{
      "fontSize": "24px",
      "marginTop": "48px",
      "marginBottom": "8px"
    }}>
  Year In Running 2022
    </h2>
    <a href="https://app.yearinrunning.com/2022" style={{
      "color": "rgb(224, 231, 34)",
      "marginTop": "0px",
      "display": "inline-block",
      "border": "none",
      "padding": "0px 15px",
      "borderRadius": "5px",
      "fontSize": "22px"
    }}>
  See your 2022
    </a>
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      